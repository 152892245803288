import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"


const mobileDesktop =()=>{
    return(
    <Layout>
         <SEO title="Manufacturing" />
        <div >
            <Image alt="military" filename="manufacturing-baner.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead">Industrial Hardware Manufacturing Services</h2>
            </Row>
            <hr className="embhr" />
            <Row>
                <Col sm={12} lg={6}>
                    <Image alt="military" filename="manufacturing-inside.png"  /></Col>
                <Col sm={12} lg={6} className="servicePagePara">
                    In the fast paced and high demand arena of Manufacturing, you need solutions to deliver on time and with Trace Width Technologies, we are there to help you achieve success. Improve your R&D, efficiency and accelerate growth with our Manufacturing engineering experience and solutions that will keep you leaps and bounds ahead of your competition.
                </Col>
               
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

